export default [
  {
    path: 'banner',
    label: 'Početna',
  },
  {
    path: 'services',
    label: 'Usluge',
  },
  {
    path: 'about',
    label: 'O nama',
  },
  {
    path: 'testimonials',
    label: 'Zadovoljni klijenti',
  },
  {
    path: 'faq',
    label: 'Često postavljana pitanja',
  },
];
