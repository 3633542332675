import React from 'react';
import { Box } from 'theme-ui';
import SwiperCore, { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import BlockTitle from 'components/block-title';
import TestimonialsCard from 'components/cards/testimonial-card';
// import testimonialsImage1 from 'assets/testimonial-1-1.png';
// import testimonialsImage2 from 'assets/testimonial-1-2.png';
// import testimonialsImage3 from 'assets/testimonial-1-3.png';
// import testimonialsImage4 from 'assets/testimonial-1-4.png';
// import testimonialsImage5 from 'assets/testimonial-1-5.png';
// import testimonialsImage6 from 'assets/testimonial-1-6.png';

SwiperCore.use([Autoplay]);

const TESTIMONIALS_DATA = [
  [
    {
      text:
        'Želim da se zahvalim kompaniji Dominus na izvanrednoj usluzi koju su nam pružili. Posebnu zahvalnost upućujem našem agentu koji je za vrlo kratko vreme pronašao savršeno rešenje za nas. Vaša posvećenost i profesionalizam su zaista impresivni. Hvala vam još jednom.',
      name: 'Marija Petrović',
    },
    {
      text:
        'Mnogo hvala na ljubaznoj i efikasnoj usluzi. Već sam preporučio vaše usluge svojim prijateljima i sigurno ću nastaviti da ih preporučujem. Vaša pomoć je bila ključna u organizaciji našeg putovanja i uštedi vremena. Hvala!',
      name: 'Stefan Lukić',
    },
  ],
  [
    {
      text:
        'Želim da pohvalim Dominus za izuzetnu profesionalnost i strpljenje koje su pokazali tokom naše saradnje. Vaša pomoć pri organizaciji posla bila je neprocenjiva, a brzina i tačnost u obavljanju istog su zaista zapanjujući. Hvala vam na svemu!',
      name: 'Ana Kostić',
    },
    {
      text:
        'Imao sam sjajno iskustvo sa kompanijom Dominus. Vaša usluga je bila brza, efikasna i veoma profesionalna. Neću oklevati da koristim vaše usluge i u budućnosti, kao i da ih preporučim svima koji traže kvalitetnu i pouzdanu pomoć u ovoj oblasti.',
      name: 'Nikola Savić',
    },
  ],
  [
    {
      text:
        'Imala sam sjajno iskustvo sa kompanijom Dominus. Vaša usluga u vezi sa elektroinstalacijama bila je brza, efikasna i veoma profesionalna. Neću oklevati da koristim vaše usluge i u budućnosti, kao i da ih preporučim svima koji traže kvalitetnu i pouzdanu pomoć u instalaciji i održavanju elektro sistema.',
      name: 'Jelena Đorđević',
    },
    {
      text:
        'Hvala vam na svim uslugama koje ste nam pružili. Vaša usluga je bila izvanredna i veoma brza. Oduševljen sam načinom na koji ste se postarali za sve detalje. Svakako ću se ponovo obratiti vama za buduću saradnju i preporučiti vas drugima.',
      name: 'Marko Nikolić',
    },
  ],
  [
    {
      text:
        'Želim da se zahvalim kompaniji Dominus na izvanrednoj usluzi koju su nam pružili. Posebnu zahvalnost upućujem našem agentu koji je za vrlo kratko vreme pronašao savršeno rešenje za nas. Vaša posvećenost i profesionalizam su zaista impresivni. Hvala vam još jednom.',
      name: 'Irena Marković',
    },
    {
      text:
        'Mnogo hvala na ljubaznoj i efikasnoj usluzi. Već sam preporučila vaše usluge svojim prijateljima i sigurno ću nastaviti da ih preporučujem. Vaša pomoć je bila ključna u organizaciji našeg putovanja i uštedi vremena. Hvala!',
      name: 'Luka Petrović',
    },
  ],
  [
    {
      text:
        'Želim da pohvalim Dominus za izuzetnu profesionalnost i strpljenje koje su pokazali tokom naše saradnje. Vaša pomoć pri organizaciji putovanja bila je neprocenjiva, a brzina i tačnost u obavljanju rezervacija su zaista zapanjujući. Hvala vam na svemu!',
      name: 'Diana Ristić',
    },
    {
      text:
        'Imala sam sjajno iskustvo sa kompanijom Dominus. Vaša usluga je bila brza, efikasna i veoma profesionalna. Neću oklevati da koristim vaše usluge i u budućnosti, kao i da ih preporučim svima koji traže kvalitetnu i pouzdanu pomoć u organizaciji putovanja.',
      name: 'Aleksandar Mitić',
    },
  ],
  [
    {
      text:
        'Imala sam sjajno iskustvo sa kompanijom Dominus. Vaša usluga je bila brza, efikasna i veoma profesionalna. Neću oklevati da koristim vaše usluge i u budućnosti, kao i da ih preporučim svima koji traže kvalitetnu i pouzdanu pomoć u organizaciji putovanja.',
      name: 'Tamara Jovanović',
    },
    {
      text:
        'Hvala vam na svim uslugama koje ste nam pružili. Vaša usluga je bila izvanredna i veoma brza. Oduševljena sam načinom na koji ste se postarali za sve detalje. Svakako ću se ponovo obratiti vama za buduća putovanja i preporučiti vas drugima.',
      name: 'Petar Stanković',
    },
  ],
];



const Testimonials = () => {
  const testimonialCarousel = {
    slidesPerView: 1,
    spaceBetween: 20,
    loop: true,
    speed: 1000,
    centeredSlides: true,
    autoHeight: true,
    autoplay: {
      waitForTransition: false,
      delay: 4000,
    },
    breakpoints: {
      640: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      1024: {
        slidesPerView: 3,
        spaceBetween: 20,
      },
      1200: {
        slidesPerView: 4,
        spaceBetween: 20,
      },
    },
  };
  return (
    <Box mt={40} pos="relative" top="150px" as="section" id="testimonials" sx={styles.testimonials}>
      <BlockTitle
        title="Šta klijenti kažu o nama"
        text="Zadovoljni klijenti"
      />
      <Swiper {...testimonialCarousel}>
        {TESTIMONIALS_DATA.map((item, index) => (
          <SwiperSlide key={index}>
            {item.map(({ image, text, name }, _index) => (
              <TestimonialsCard
                text={text}
                name={name}
                key={_index}
              />
            ))}
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
};

export default Testimonials;

const styles = {
  testimonials: {
    backgroundColor: '#F4F4F6',
    pt: ['80px', null, null, null, '80px', null, '100px'],
    pb: ['60px', null, null, null, '80px', null, '120px'],
  },
};
