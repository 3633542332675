import React from 'react';
import { Box, Text, Container, Flex } from 'theme-ui';

export default function Footer() {
  return (
    <Box as="footer" sx={styles.footer}>
      <Container sx={styles.container}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: ['column', null, null, null, null, 'row'],
            mx: "auto",
          }}
        >
          <Flex>
            <Text sx={styles.logo}>Dom<b style={{ color: "#FFC32C" }}>i</b>nus</Text>
            <Text color="#fff">Electricity</Text>
          </Flex>
          <Text
            as="p"
            sx={{
              color: '#ffffff',
              opacity: '0.7',
              fontSize: '14px',
              mt: ['10px', null, null, null, null, '0'],
              ml: "20px"
            }}
          >{" "}
            Copyright  {new Date().getFullYear()} by Dominus Electricity
          </Text>
        </Box>
      </Container>
    </Box>
  );
}

const styles = {
  logo: {
    fontSize: '24px',
    fontWeight: 'bold',
    color: '#ffffff',
  },
  footer: {
    py: ['40px', null, null, null, null, '30px', '40px'],
    backgroundColor: '#020718',
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: '#ffffff',
      opacity: '0.05',
    },
  },
  container: {
    display: 'flex',
    flexDirection: ['column', null, null, null, null, 'row'],
    justifyContent: ['center', null, null, null, null, 'space-between'],
    alignItems: 'center',
    position: 'relative',
    flexWrap: 'wrap',
  },
  linksWrap: {
    mt: ['15px', null, null, null, null, '0'],
    display: 'flex',
    flexWrap: 'wrap',
    a: {
      fontSize: ['14px', null, null, null, '16px'],
      color: '#ffffff',
      transition: 'all 500ms ease',
      '&:hover': { opacity: 0.7 },
    },
    'a+a': { ml: ['15px', null, null, null, '35px'] },
    navLink: {
      fontSize: '16px',
      color: '#ffffff',
      fontWeight: '400',
      cursor: 'pointer',
      lineHeight: '1.2',
      transition: '500ms',
      ':last-child': {
        mr: '0',
      },
      '&:hover, &.active': {
        color: 'primary',
      },
    },
  },
};
