import React from 'react';
import { Box, Container, Flex, Text, Heading } from 'theme-ui';
import { Link } from 'components/link';
import BlockTitle from 'components/block-title';
import Accordion from 'components/accordion/accordion';

const accordionData = [
  {
    isExpanded: false,
    title: 'Šta obuhvata razvod električnih instalacija?',
    contents: (
      <div>
        Razvod električnih instalacija obuhvata sve faze postavljanja novih električnih vodova u objektu. To uključuje postavljanje kablova, instaliranje osigurača, sklopki, prekidača i drugih elemenata potrebnih za pravilno funkcionisanje električnog sistema.
      </div>
    ),
  },
  {
    isExpanded: false,
    title: 'Kako se obavlja popravka starih instalacija?',
    contents: (
      <div>
        Popravka starih instalacija uključuje identifikaciju i otklanjanje kvarova, zamenu oštećenih delova i unapređenje sistema kako bi bio usklađen sa savremenim standardima. Ovo može uključivati zamenu starih kablova i komponenti.
      </div>
    ),
  },
  {
    isExpanded: false,
    title: 'Koji su koraci u instaliranju osigurača, sklopki, prekidača i trafoa?',
    contents: (
      <div>
        Instaliranje osigurača, sklopki, prekidača i trafoa podrazumeva njihovu pravilnu montažu i povezivanje sa električnim sistemom. Svaki deo se instalira kako bi obezbedio sigurnost i kontrolu električne energije u objektu.
      </div>
    ),
  },
  {
    isExpanded: false,
    title: 'Kako se postavljaju uređaji električne rasvete?',
    contents: (
      <div>
        Postavljanje uređaja električne rasvete uključuje montažu svetiljki, rasvetnih tela i njihove povezivanje sa električnim sistemom. Ovaj proces obuhvata pravilno postavljanje i podešavanje svetlosnih uređaja prema potrebama prostora.
      </div>
    ),
  },
  {
    isExpanded: false,
    title: 'Šta uključuje održavanje postavljenih uređaja?',
    contents: (
      <div>
        Održavanje postavljenih uređaja obuhvata redovne provere i servisiranje kako bi se obezbedila njihova dugotrajnost i ispravno funkcionisanje. To uključuje čišćenje, popravke i zamene delova kada je to potrebno.
      </div>
    ),
  },
  {
    isExpanded: false,
    title: 'Kako se otklanjaju kvarovi i postavljaju novi električni vodovi?',
    contents: (
      <div>
        Otklanjanje kvarova uključuje dijagnostiku problema u električnom sistemu i njihovo rešavanje. Ako je potrebno, zamena starih vodova i postavljanje novih obezbeđuje pouzdan rad sistema.
      </div>
    ),
  },
  {
    isExpanded: false,
    title: 'Šta je ugradnja razvodnih ormara?',
    contents: (
      <div>
        Ugradnja razvodnih ormara podrazumeva instaliranje ormara koji sadrže prekidače, osigurače i druge komponente za distribuciju električne energije u objektu. Ovo je ključno za organizaciju i kontrolu električnih vodova.
      </div>
    ),
  },
  {
    isExpanded: false,
    title: 'Kako se vrši priključenje električnih uređaja i opreme?',
    contents: (
      <div>
        Priključenje električnih uređaja i opreme uključuje povezivanje uređaja sa električnim sistemom, osiguravajući da svi uređaji funkcionišu ispravno i sigurno.
      </div>
    ),
  },
  {
    isExpanded: false,
    title: 'Šta je preventivno održavanje električne opreme?',
    contents: (
      <div>
        Preventivno održavanje električne opreme podrazumeva redovne proaktive provere i servisiranje kako bi se sprečili potencijalni problemi i kvarovi pre nego što se dogode.
      </div>
    ),
  },
  {
    isExpanded: false,
    title: 'Koje vrste elektroinstalacija izvodite?',
    contents: (
      <div>
        Izvodimo različite vrste elektroinstalacija uključujući kućne elektroinstalacije, protivpožarne elektroinstalacije, instalacije osvetljenja, računarskih mreža, telekomunikacionih mreža i interfona.
      </div>
    ),
  },
];

const FAQ = () => {
  return (
    <Box as="section" id="faq">
      <Container mt="80px">
        <BlockTitle
          title="Često postavljana pitanja"
          text="Sve što Vas zanima"
        />
        <Flex sx={styles.flex}>
          <Box sx={styles.faqWrapper}>
            <Accordion items={accordionData} />
          </Box>
          <Box sx={styles.content}>
            <Heading as="h3">
              Imate pitanje? Kontaktirajte nas
            </Heading>
            <Text as="p">
              Ukoliko niste pronašli odgovor koji tražite, pišite nam putem forme
            </Text>
            <Link sx={styles.askButton} path="#">
              Kontaktirajte nas
            </Link>
          </Box>
        </Flex>
      </Container>
    </Box>
  );
};

export default FAQ;

const styles = {
  flex: {
    flexWrap: 'wrap',
    flexDirection: ['column', null, null, null, null, 'row-reverse'],
    pb: ['70px', null, null, null, '90px', null, '130px'],
  },
  content: {
    flex: ['0 0 100%', null, null, null, '0 0 33.333%'],
    maxWidth: ['100%', null, null, '450px', '100%'],
    mx: ['auto', null, null, null, '0'],
    mb: ['0px', null, null, null, '0'],
    textAlign: ['center', null, null, null, null, 'left'],
    mt: ['40px', null, null, null, null, '0'],
    h3: {
      fontSize: ['23px', null, null, null, '24px'],
      lineHeight: [1.5, null, null, null, 1.67],
      color: 'black',
      fontWeight: 700,
      letterSpacing: '-1.5px',
      mt: '-5px',
      pr: ['0', null, null, null, null, '30px'],
    },
    p: {
      fontSize: '16px',
      lineHeight: 1.87,
      color: '#343D48',
      opacity: 0.7,
      mt: '10px',
      pr: ['0', null, null, null, null, '80px'],
    },
  },
  askButton: {
    backgroundColor: "#FFC32C",
    display: 'inline-block',
    verticalAlign: 'middle',
    color: '#fff',
    borderRadius: '5px',
    fontSize: '16px',
    fontWeight: 700,
    p: '6.5px 19px',
    letterSpacing: '-0.16px',
    mt: '25px',
    transition: 'all 500ms ease',
    '&:hover': {
      opacity: 0.8,
    },
  },
  faqWrapper: {
    flex: ['0 0 100%', null, null, null, '0 0 66.666%'],
  },
};
