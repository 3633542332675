import React from 'react';
import { Box, Container, Grid } from 'theme-ui';
import BlockTitle from 'components/block-title';
import ServiceCard from 'components/cards/service-card';
import serviceImage1 from 'assets/engineering.png';
import serviceImage2 from 'assets/wire.png';
import serviceImage3 from 'assets/boiler.png';
import serviceImage4 from 'assets/stove.png';
import serviceImage5 from 'assets/central-heating.png';
import serviceImage6 from 'assets/light-bulb.png';

const SERVICES_DATA = [
  {
    image: serviceImage1,
    text:
      'Zamena starih osigurača novim automatskim osiguračima je jedna od bitnijih radnji koje obavljamo.',
    heading: 'Zamena osigurača',
    
  },
  {
    image: serviceImage2,
    text:
      'Nove elektro-instalacije, nadogradnje ili prepravke postojeće instalacije.',
    heading: 'Postavljanje / Popravka elektro instalacije',
    
  },
  {
    image: serviceImage3,
    text:
      'Zamena grejača, čišćenje kamenca. Zamena termostata. Postavljanje novog bojlera.',
    heading: 'Servis bojlera',
    
  },
  {
    image: serviceImage4,
    text:
      'Popravljamo i vršimo ugradnju svih tipova šporeta i rerni.',
    heading: 'Popravka šporeta',
    
  },
  {
    image: serviceImage5,
    text:
      'Zamena grejača, termostata, ventilatora...',
    heading: 'Remont i popravka TA peći',
    
  },
  {
    image: serviceImage6,
    text:
      'Ugradnja moderne rasvete, led traka, pozadinskog osvetljenja',
    heading: 'Ugradnja i popravka rasvete',
    
  },
];

const Services = () => {
  return (
    <Box as="section" id="services" sx={styles.services}>
      <Container>
        <BlockTitle
          title="Vrste usluga koje nudimo"
          text="Neke od izdvojenih"
        />
        <Grid sx={styles.grid}>
          {SERVICES_DATA.map(({ image, text, heading }, index) => (
            <ServiceCard
              image={image}
              text={text}
              heading={heading}
              key={index}
            />
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default Services;

const styles = {
  services: {
    pt: ['80px', null, null, null, '80px', null, '100px'],
  },
  grid: {
    gridGap: '30px',
    gridTemplateColumns: ['1fr', null, null, '1fr 1fr', null, '1fr 1fr 1fr'],
  },
};
