import React, { useState } from 'react';
import { Box, Container, Button } from 'theme-ui';
import { useStaticQuery, graphql } from 'gatsby';
import { keyframes } from '@emotion/core';
import BlockTitle from 'components/block-title';
import Image from 'components/image';
import { AiFillPieChart } from 'react-icons/ai';
import { FaBriefcase, FaIndustry, FaBuilding } from 'react-icons/fa';
import { IoIosConstruct } from "react-icons/io";



import dotPattern from 'assets/dot-pattern.svg';

const Featured = () => {
  const data = useStaticQuery(graphql`
    {
  image1: file(relativePath: {eq: "tab-image-1.webp"}) {
    childImageSharp {
      fluid(maxWidth: 1280, maxHeight: 600) {
        ...GatsbyImageSharpFluid
      }
    }
  }
  image2: file(relativePath: {eq: "tab-image-2.webp"}) {
    childImageSharp {
      fluid(maxWidth: 1280, maxHeight: 600) {
        ...GatsbyImageSharpFluid
      }
    }
  }
  image3: file(relativePath: {eq: "tab-image-3.webp"}) {
    childImageSharp {
      fluid(maxWidth: 1280, maxHeight: 600) {
        ...GatsbyImageSharpFluid
      }
    }
  }
  image4: file(relativePath: {eq: "tab-image-4.webp"}) {
    childImageSharp {
      fluid(maxWidth: 1280, maxHeight: 600) {
        ...GatsbyImageSharpFluid
      }
    }
  }
  image5: file(relativePath: {eq: "tab-image-5.webp"}) {
    childImageSharp {
      fluid(maxWidth: 1280, maxHeight: 600) {
        ...GatsbyImageSharpFluid
      }
    }
  }
}

  `);

  const [tab, setTab] = useState({
    active: 'objekti',
  });

  const handleTab = (tab) => {
    if (tab === 'objekti') {
      setTab({
        ...tab,
        active: 'objekti',
      });
    }
    if (tab === 'poslovni') {
      setTab({
        ...tab,
        active: 'poslovni',
      });
    }
    if (tab === 'industrija') {
      setTab({
        ...tab,
        active: 'industrija',
      });
    }
    if (tab === 'poljoprivreda') {
      setTab({
        ...tab,
        active: 'poljoprivreda',
      });
    }
    if (tab === 'gradiliste') {
      setTab({
        ...tab,
        active: 'gradiliste',
      });
    }
  };
  return (
    <Box as="section" sx={styles.featured}>
      <Container sx={styles.container}>
        <BlockTitle
          title="Gde se sve izvode električne instalacije?"
          text="Električne instalacije se izvode u:"
        />
        <Box sx={styles.tabButtonTopWrapper}>
          <Box sx={styles.tabButtonWrapper}>
            <Button
              onClick={() => handleTab('objekti')}
              className={`${tab.active === 'objekti' ? 'active' : ''}`}
            >
              <FaBuilding />
              Stambenim objektima
            </Button>
            <Button
              onClick={() => handleTab('poslovni')}
              className={`${tab.active === 'poslovni' ? 'active' : ''}`}
            >
              <FaBriefcase />
              Poslovnim prostorima
            </Button>
            <Button
              onClick={() => handleTab('industrija')}
              className={`${tab.active === 'industrija' ? 'active' : ''}`}
            >
              <FaIndustry />
              Industriji
            </Button>
            <Button
              onClick={() => handleTab('poljoprivreda')}
              className={`${tab.active === 'poljoprivreda' ? 'active' : ''}`}
            >
              <AiFillPieChart />
              Poljoprivrednim dobrima
            </Button>
            <Button
              onClick={() => handleTab('gradiliste')}
              className={`${tab.active === 'gradiliste' ? 'active' : ''}`}
            >
              <IoIosConstruct />
              Gradilištima
            </Button>
          </Box>
        </Box>
        <Box sx={styles.tabContent}>
          {tab.active === 'objekti' && (
            <Image
              src={data.image1.childImageSharp.fluid}
              alt="Stambeni objekti image"
              className="tabImage"
            />
          )}
          {tab.active === 'poslovni' && (
            <Image
              src={data.image2.childImageSharp.fluid}
              alt="Poslovni prostori image"
              className="tabImage"
            />
          )}
          {tab.active === 'industrija' && (
            <Image
              src={data.image3.childImageSharp.fluid}
              alt="Industrija image"
              className="tabImage"
            />
          )}
          {tab.active === 'poljoprivreda' && (
            <Image
              src={data.image4.childImageSharp.fluid}
              alt="Poljoprivredna dobra image"
              className="tabImage"
            />
          )}
          {tab.active === 'gradiliste' && (
            <>
            <Image
              src={data.image5.childImageSharp.fluid}
              alt="Gradilišta image"
              className="tabImage"
            />
            </>
          )}
        </Box>

      </Container>
    </Box>
  );
};

export default Featured;
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const styles = {
  featured: {
    pt: ['80px', null, null, null, '80px', null, '100px'],
    backgroundColor: '#F9FAFC',
  },
  container: {
    position: 'relative',
  },
  tabButtonTopWrapper: {
    overflowY: ['hidden', null, null, null, null, 'inherit'],
    overflowX: ['auto', null, null, null, null, 'inherit'],
  },
  tabButtonWrapper: {
    width: ['700px', null, null, null, null, '100%'],
    mx: ['auto', null, null, null, null, '0'],
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderBottom: '1px solid rgba(1,7,13,.1)',
    mb: '35px',
    button: {
      display: 'flex',
      alignItems: 'center',
      pb: ['15px', null, null, null, '35px'],
      px: ['15px', null, null, null, '30px'],
      flexShrink: '0',
      border: 0,
      backgroundColor: 'rgba(0,0,0,0)',
      color: '#0F2137',
      fontSize: ['14px', null, null, null, '18px'],
      fontWeight: 500,
      lineHeight: 1,
      position: 'relative',
      transition: 'all 500ms ease',
      svg: {
        fontSize: ['18px', null, null, null, '30px'],
        color: '#ADBDD0',
        opacity: 0.7,
        mr: '15px',
        transition: 'all 500ms ease',
      },
      '&:hover, &.active': {
        backgroundColor: 'rgba(0,0,0,0)',
        color: '#FFC32C',
        svg: {
          color: '#FFC32C',
          opacity: 1,
        },
        '&::before': {
          transform: 'scale(1,1)',
        },
      },
      '&::before': {
        content: "''",
        position: 'absolute',
        bottom: '-2px',
        backgroundColor: '#FFC32C',
        left: 0,
        width: '100%',
        height: '3px',
        transform: 'scale(0,1)',
        transition: 'transform 500ms ease',
      },
    },
  },
  tabContent: {
    minHeight: ['190px', null, '300px', '385px', null, '600px'],
    position: 'relative',
    '&::before': {
      content: "''",
      width: '302px',
      height: '347px',
      backgroundImage: `url(${dotPattern})`,
      position: 'absolute',
      bottom: '-30px',
      right: '-40px',
      display: ['none', null, null, null, null, 'block'],
    },
    '.tabImage': {
      position: 'relative',
      animation: `${fadeIn} 0.8s linear`,
    },
  },
};
