import React from 'react';
import { Box, Container, Heading, Text, Link } from 'theme-ui';
import { keyframes } from '@emotion/core';
import bannerIcon1 from 'assets/lightbulb.svg';

const Subscribe = () => {
  // Definisanje animacije za ikonu
  const bannerAnim2 = keyframes`
    0% {
      transform: translateY(0px) translateX(0) rotate(0);
    }
    30% {
      transform: translateY(30px) translateX(30px) rotate(15deg);
      transform-origin: center center;
    }
    50% {
      transform: translateY(50px) translateX(50px) rotate(45deg);
      transform-origin: right bottom;
    }
    80% {
      transform: translateY(30px) translateX(30px) rotate(15deg);
      transform-origin: left top;
    }
    100% {
      transform: translateY(0px) translateX(0) rotate(0);
      transform-origin: center center;
    }
  `;

  return (
    <Box
      as="section"
      sx={{
        position: 'relative',
        backgroundColor: '#FFC32C',
        py: ['80px', null, null, null, '80px', '100px', '140px'],
        mx: 'auto',
        textAlign: 'center',
        overflow: 'hidden',
      }}
    >
      <Container >
        <Heading as="h3" sx={styles.heading}>
          Kontaktirajte nas putem email-a ili pozovite
        </Heading>
        <Box sx={styles.contactInfo}>
          <Link
            href="mailto:dominus.electricity@gmail.com"
            color="#020718"
            sx={styles.link}
          >
            <Text as="h4">dominus.electricity@gmail.com</Text>
          </Link>
          <Link
            href="tel:+38162516276"
            color="#020718"
            sx={styles.link}
          >
            <Text as="h4">062 516 276</Text>
          </Link>
        </Box>
        <Box
          as="img"
          src={bannerIcon1}
          alt="Lightbulb Icon"
          sx={{
            position: 'absolute',
            top: '10%',
            left: '50%',
            width: '40px',
            animation: `${bannerAnim2} 8s linear infinite`,
          }}
        />
      </Container>


    </Box>
  );
};

export default Subscribe;

const styles = {
  heading: {
    fontSize: ['23px', null, null, null, null, '30px', '36px'],
    lineHeight: [1.5, null, null, '1'],
    color: '#fff',
    letterSpacing: ['-0.5px'],
    mb: ['0px', null, null, '15px'],
    width: ['70%', null, null, 'auto'],
    mx: ['auto', null, null, '0'],
  },
  contactInfo: {
    mt: '15px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  link: {
    '&:hover': {
      textDecoration: 'underline',
    },
    color: '#020718',
    textAlign: 'center',
    mb: '10px',
  },
};
