import React from 'react';
import { Box, Container, Flex, Image, Text, Heading } from 'theme-ui';
import jackpotImage from 'assets/cable-wall.jpg';


const Jackpot = () => {
  return (
    <Box as="section" id="about" sx={styles.jackpot}>
      <Container>
        <Flex sx={styles.flex}>
          <Box sx={styles.image}>
            <Image sx={styles.cableImg} src={jackpotImage} alt="jackpot image" />
          </Box>
          <Box sx={styles.content}>
            <Box sx={styles.heading}>
              <Text as="span">Ko smo mi?</Text>
              <Heading as="h3">Dominus Elektro</Heading>
            </Box>
            <Box sx={styles.jackpotCardBox}>
              <Text pl="30px">
                Dominus Electricity je renomirana kompanija sa više od 5 godina iskustva u oblasti elektro usluga. Naša stručnost pokriva širok spektar elektroinstalaterskih radova, uključujući postavljanje i održavanje elektroinstalacija, ugradnju i servisiranje električnih uređaja, kao i optimizaciju energetske efikasnosti u domaćinstvima i poslovnim prostorima. Kroz dosledan rad i inovativna rešenja, pružamo visokokvalitetne usluge koje zadovoljavaju potrebe naših klijenata.</Text>
            </Box>
          </Box>
        </Flex>
      </Container>
    </Box>
  );
};

export default Jackpot;

const styles = {
  jackpot: {
    pt: ['65px', null, null, null, '85px', null, '125px'],
  },
  flex: {
    flexWrap: 'wrap',
  },
  image: {
    flex: ['0 0 100%', null, null, null, null, '0 0 62.5%'],
    img: {
      maxWidth: ['100%', null, null, null, null, null, 'none'],
      float: 'right',
    },
  },
  content: {
    flex: ['0 0 100%', null, null, null, null, '0 0 37.5%'],
  },
  heading: {
    mb: '0px',
    pt: '16px',
    textAlign: ['center', null, null, null, null, 'left'],
    pl: ['0', null, null, '30px'],
    maxWidth: ['80%', null, null, '100%'],
    mx: ['auto', null, null, '0'],
    span: {
      display: 'block',
      fontSize: '18px',
      color: 'primary',
      fontWeight: 700,
      lineHeight: 1,
      mb: '10px',
    },
    h3: {
      color: '#0F2137',
      fontSize: ['23px', null, null, null, '30px', '36px', '44px'],
      maxWidth: ['100%', null, null, null, null, '90%', '100%'],
      fontWeight: 700,
      letterSpacing: '-1.5px',
      lineHeight: 1.36,
    },
  },
  cableImg: {
    width: '100%',
    height: '400px',
    objectFit: "cover",
  },
  jackpotCardBox: {
    display: ['grid', null, null, null, null, 'block'],
    gridTemplateColumns: ['1fr', null, null, '1fr 1fr'],
  },
};
