import React from 'react';
import { Box, Container, Flex, Text, Heading, Image } from 'theme-ui';

import callImage from 'assets/electric.png';

const CallToAction = () => {
  return (
    <Box as="section" sx={styles.callToAction}>
      <Container>
        <Flex sx={styles.flex}>
          <Box sx={styles.content}>
            <Text as="span">Profesionalnost i efikasnost</Text>
            <Heading as="h3">
              Zašto Dominus Electricity?
            </Heading>
            <Text as="p">
              Naš tim se sastoji od visoko kvalifikovanih električara koji se kontinuirano usavršavaju kako bi pružili najkvalitetnije usluge našim klijentima. Posvećeni smo sigurnosti, pouzdanosti i inovacijama, te nastojimo da našim klijentima obezbedimo najviši standard usluga uz garanciju na sve naše radove.
            </Text>
           
          </Box>
          <Box sx={styles.images}>
            <Image sx={styles.img} src={callImage} alt="call image" />
          </Box>
        </Flex>
      </Container>
    </Box>
  );
};

export default CallToAction;

const styles = {
  callToAction: {
    mt: ['-90px', null, null, null, '0'],
    py: ['50px', null, null, null, '110px', null, '140px'],
  },
  flex: {
    flexWrap: 'wrap',
    justifyContent: "space-between",
  },
  content: {
    flex: ['0 0 100%', null, null, null, '0 0 44%'],
    textAlign: ['center', null, null, null, 'left'],
    pt: ['80px', null, null, null, '0'],
    maxWidth: ['100%', null, null, '80%', '100%'],
    mx: ['auto', null, null, null, '0'],
    mb: ['30px', null, null, null, '0'],
    span: {
      fontSize: '18px',
      fontWeight: 700,
      color: 'primary',
      display: 'block',
      lineHeight: 1,
    },
    h3: {
      color: '#0F2137',
      fontWeight: 700,
      fontSize: ['23px', null, null, null, '30px', '36px', '44px'],
      maxWidth: ['100%', null, null, null, null, '90%', '100%'],
      lineHeight: 1.36,
      letterSpacing: '-1.5px',
      mt: '10px',
      mb: '0px',
    },
    p: {
      color: '#02073E',
      fontSize: ['16px', null, null, '18px'],
      lineHeight: ['2', null, null, 2.33],
      mb: '30px',
    },
  },
  button: {
    display: 'inline-block',
    verticalAlign: 'middle',
    backgroundColor: '#02073E',
    color: '#fff',
    borderRadius: '5px',
    fontSize: '16px',
    fontWeight: 700,
    p: '6.5px 19px',
    letterSpacing: '-0.16px',
    transition: 'all 500ms ease',
    '&:hover': {
      opacity: 0.8,
    },
  },
  images: {
    flex: ['0 0 100%', null, null, null, '0 0 44%'],
  },
  img: {
    borderRadius: "12px",
  }
};
