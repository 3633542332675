import React from 'react';
import { StickyProvider } from 'contexts/app/app.provider';
import SEO from 'components/seo';
import Layout from 'components/layout';
import Banner from 'sections/banner';
import Services from 'sections/services';
import Jackpot from 'sections/jackpot';
import CallToAction from 'sections/call-to-action';
import Featured from 'sections/featured';
import Testimonials from 'sections/testimonials';
import FAQ from 'sections/faq';
import Subscribe from 'sections/subscribe';

import 'swiper/swiper-bundle.min.css';
import 'rc-drawer/assets/index.css';
import 'typeface-roboto';


export default function IndexPage() {
  return (
    <StickyProvider>
      <Layout>
        <SEO title="Dominus electricity | Vaš pouzdan partner za sve elektro usluge." />
        <Banner />
        <Services />
        <Jackpot />
        <CallToAction />
        <Featured />
        {/* <Pricing /> */}
        <Testimonials />
        <FAQ />
        <Subscribe />
      </Layout>
    </StickyProvider>
  );
}
